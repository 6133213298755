import AllasLogo from '@/allas/public/image/allas-logo.svg';
import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const currentYear = new Date().getFullYear();

const logo: StandaloneFooterProps['logo'] = <AllasLogo />;

const description: StandaloneFooterProps['description'] = (
  <>
    Allas.se erbjuder gripande journalistik kring ämnen som relationer, hälsa och handarbete samt innehåll från veckotidningarna Allas, Allers, Hemmets
    Veckotidning, Året Runt, Allers Trädgård och Antik & Auktion.
  </>
);

const editors: StandaloneFooterProps['editors'] = 'Ansvarig utgivare: Åsa Liliegren';

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>
    <Footer.SoMe
      href="https://www.facebook.com/allasveckotidning"
      name="facebook"
      aria-label="Länk till Facebook"
      options={{ colors: 'filled' }}
    />
    <Footer.SoMe
      href="https://www.instagram.com/allas.se"
      name="instagram"
      aria-label="Länk till Instagram"
      options={{ colors: 'filled' }}
    />
    <Footer.SoMe
      href="https://www.youtube.com/channel/UCbhwNRc_wF3tpAkM2feMTFA"
      name="youtube"
      aria-label="Länk till Youtube"
      options={{ colors: 'filled' }}
    />
    <Footer.SoMe
      href="https://www.pinterest.se/allas_se"
      name="pinterest"
      aria-label="Länk till Pinterest"
      options={{ colors: 'filled' }}
    />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAqBwgKMPKfiwswqd3qAg?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        colors: 'none',
        className:
          'border-transparent bg-logga-500 border-logga-500 hover:bg-logga-600 focus:bg-logga-500 active:bg-logga-800 text-white',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-5" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
      aria-label="Länk till Google news"
    />
  </Footer.SoMe.Group>
);

const columns: StandaloneFooterProps['columns'] = (
  <>
    <Footer.Column>
      <Footer.List.Title>Om Allas</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/nyhetsbrev" content="Nyhetsbrev" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/alla-amnen" content="Alla ämnen" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/vara-skribenter" content="Våra skribenter" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/om-cookies" content="Om cookies" />
        </Footer.List.Item>
        <Footer.List.Item>
          <button type="button" onClick={showOneTrustConsent}>
            Hantera preferenser
          </button>
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/integritetspolicy" target="_blank" content="Integritetspolicy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/ai-policy/" target="_blank" content="Aller Medias AI-policy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-allasse/10293627"
            content="Kommersiell policy Allas"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Allas.se</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/livsoden" content="Livsöden" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/halsa" content="Hälsa" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/relationer" content="Relationer" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/handarbete" content="Handarbete" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/tradgard" content="Trädgård" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/noje" content="Nöje" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Kontakta oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/kontakta-oss" content="Kontakta oss" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://ocast.com/se/aller-media" target="_blank" content="Annonsera hos oss" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se" target="_blank" content="Prenumerera" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/allas/" target="_blank" content="Allas" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/allers/" target="_blank" content="Allers" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/allers-tradgard/" target="_blank" content="Allers Trädgård" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se/tidningar/hemmets-veckotidning/"
            target="_blank"
            content="Hemmets Veckotidning"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/aret-runt/" target="_blank" content="Året runt" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/antik--auktion/" target="_blank" content="Antik auktion" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>
  </>
);

const copyright: StandaloneFooterProps['copyright'] = (
  <>
    <p>
      För prenumerationsärenden, ring{' '}
      <a className="hover:underline" href="tel:0424443013">
        042 444 30 00
      </a>{' '}
      • Ansvarig utgivare Åsa Liliegren © Copyright {currentYear}{' '}
      <a className="hover:underline" href="https://www.allas.se">
        allas.se
      </a>
    </p>
    <p>
      Allas är en del av{' '}
      <a className="hover:underline" target="_blank" href="https://www.aller.se">
        Aller media
      </a>
      . Humlegårdsgatan 6, 114 46 Stockholm.{' '}
      <a className="hover:underline" href="tel:086794600">
        08 679 46 00
      </a>
    </p>
  </>
);

export const AllasStandaloneFooter: typeof StandaloneFooter = () => {
  return <StandaloneFooter {...{ logo, description, editors, soMe, columns, copyright }} />;
};
